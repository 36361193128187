<template>
    <div>
        <div>
            <a href="#" class="btn btn-minier btn-yellow" @click.prevent="check(templateArgs.data.dados.notification_id)" v-if="templateArgs.data.dados.notification_id">Check</a>
            <a href="#" class="btn btn-minier btn-primary" @click.prevent="cadastro(templateArgs.data.dados)" v-else>Gravar</a>
            <a href="#" class="btn btn-minier" :class="`${templateArgs.data.dados.ativo == 1 ? 'btn-success' : 'btn-danger'}`" @click.prevent="publicar(templateArgs.data.dados.id, templateArgs.data.dados.ativo)">{{ templateArgs.data.dados.ativo == 1 ? 'Publicado' : 'Publicar' }}</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'

export default {
    name: 'templateImagem',
    data: () => ({
        templateArgs: {}
    }),
    methods: {
        async publicar(id, ativo) {
            const body = {
                ativo: ativo == 1 ? 2 : 1
            }
            console.log(body)
            await Services.put(`/eventos/${id}`, body).catch(response => {
                console.log('error: ' + response)
                erro()
            })
            console.log(this.$refs)
            this.templateArgs.data.ref.kendoDataSource.read()
            sucesso('Atualizado...')
        },
        async cadastro(item) {
            console.log(item.inicio)
            window.moment = moment
            window.inicio = item.inicio
            const req = axios.create({ baseURL: 'https://onesignal.com/api/v1' })
            delete req.defaults.headers.common['Authorization']
            req.defaults.headers.common.authorization = 'Bearer ZmIzNmE3N2UtYjdmNy00MjI1LTk2NzctNDNmZmFkYTYyMzk0'
            req.defaults.headers.common.accept = 'application/json'
            req.defaults.headers.common['Content-Type'] = 'application/json'

            const data = {
                app_id: 'd86452ab-9e22-45c3-a315-c5eb2b220e90',
                name: item.title,
                included_segments: ['Todos'],
                headings: {
                    en: item.title_en,
                    es: item.title_es,
                    pt: item.title
                },
                contents: {
                    en: 'get the coordinates with the times in Events =D',
                    es: 'saca las coordenadas con los tiempos en Eventos =D',
                    pt: 'pegar as coordenadas com os horários em Eventos =D'
                },
                //send_after: '2024-01-12 00:46:00 GMT-0400',
                send_after: moment(item.inicio).subtract(17, 'hours').format('YYYY-MM-DD HH:mm:ss [GMT-0400]'),
                big_picture: item.imagem,
            }
            const resp = await req.post('/notifications', data)
            const body = {
                notification_id: resp.data.id
            }
            await Services.put(`/eventos/${item.id}`, body).catch(response => {
                console.log('error: ' + response)
                erro()
            })
            this.templateArgs.data.ref.kendoDataSource.read()
        },
        async check (notification_id) {
            const resp = await axios.get(`https://onesignal.com/api/v1/notifications/${notification_id}?app_id=d86452ab-9e22-45c3-a315-c5eb2b220e90`, {
                headers: {
                    Authorization: 'Bearer ZmIzNmE3N2UtYjdmNy00MjI1LTk2NzctNDNmZmFkYTYyMzk0',
                    accept: 'application/json'
                }
            })
            console.log(
                resp
            )
        }
    }
}
</script>

<style lang="scss" scoped>
</style>