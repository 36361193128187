<template>
  <div>
    <page-header :title="title" :url="url" :titleNew="titleNew"></page-header>
    <div class="row">
      <div class="col-md-4">
        <input-form-text textInput="Título" :required="true" v-model="filtro.titulo" :value="filtro.titulo" :layout="true"></input-form-text>
      </div>
      <div class="col-md-2">
        <button class="btn btn-primary" @click.prevent="getLimparFiltro">Limpar</button>
      </div>
    </div><br/>
    <vue-element-loading :active="isActive" spinner="bar-fade-scale" color="#428BCA"></vue-element-loading>
    <grid
        ref="grid"
        :url="urlDataSource"
        :source="source"
        :columns="columns"
        :grid="grid"
      ></grid>
  </div>
</template>

<script>
import VueElementLoading from 'vue-element-loading'
import jwt from 'jwt-simple'
import PageHeader from '@/components/PageHeader'
import Grid from '@/components/Grid'

import GridTemplate from '@/mixins/GridTemplate'
import Permission from '@/mixins/Permission'
import TemplateImages from './Eventos/TemplateImagem.vue'
import TemplateNotificacao from './Eventos/TemplateNotificacao.vue'

import InputFormText from '@/components/form/InputForm'

import swal from '@/utils/swal'
import Services from '@/utils/services'

export default {
  mixins: [GridTemplate, Permission],
  components: {
    PageHeader,
    Grid,
    VueElementLoading,
    InputFormText
  },
  computed: {
    filter() {
      return {
        logic: 'and',
        filters: [
          {
            field: 'titulo',
            operator: 'contains',
            value: this.filtro.titulo
          }
        ]
      }
    },
    urlDataSource() {
      return `${this.$url}/eventos/all`
    },
    source() {
      return [
        {
          serverFiltering: true,
          pageSize: 15,
          serverPaging: true,
          schemaData: 'data',
          schemaTotal: 'total',
          serverSorting: true,
          schemaParse: this.parse,
          filter: this.filter,
        }
      ]
    }
  },
  watch: {
    'filtro.titulo'() {
      this.setFiltro()
    }
  },
  data() {
    return {
      title: 'Eventos',
      titleNew: '',
      url: '/sistema/eventos/',
      columns: [
        {
          template: this.getTemplateImages,
          title: 'Imagem',
          width: 40,
        },
        {
          field: 'title',
          title: 'Título',
          width: 100
        },
        {
          field: 'inicio',
          title: 'Inicio',
          format: '{0:dd/MM/yyyy HH:mm}',
          width: 60
        },
        {
          field: 'fim',
          title: 'Fim',
          format: '{0:dd/MM/yyyy HH:mm}',
          width: 60
        },
        {
          field: 'created_at',
          title: 'Dt Cadastro',
          format: '{0:dd/MM/yyyy HH:mm}',
          width: 60
        },
        {
          field: 'ativo_nome',
          title: 'Ativo',
          width: 30
        },
      ],
      filtro: {
        titulo: null,
      },
      isActive: false,
      grid: [
        {
          height: 500,
          navigatable: true,
          columnMenu: true,
          sortable: false,
          pageable: true,
          pageablePageSizes: [15, 50, 75, 100],
          pageableAlwaysVisible: true
        }
      ]
    }
  },
  methods: {
    parse(d) {
      d.data.map(i => {
        i.created_at = new Date(i.created_at)
        i.inicio = new Date(i.inicio)
        i.fim = new Date(i.fim)
        return i
      })
      return d
    },
    getTemplateImages(e) {
      return {
        template: TemplateImages,
        templateArgs: {
          data: e
        }
      }
    },
    async getApagar(id) {
      const dialog = await swal.dialog(
        'Excluir',
        'Deseja excluir esse registro?'
      )
      if (dialog.value) {
        await Services.delete(`/eventos/${id}`)
        swal.success('Excluido!', 3000)
        this.$refs.grid.$refs.datasource1.kendoDataSource.read()
      }
    },
    setFiltro() {
      let filtro = {
        titulo: this.filtro.titulo,
      }
      localStorage.setItem('_filtroEventos', JSON.stringify(filtro))
    },
    getLimparFiltro() {
      let filtro = {
        titulo: null,
      }
      localStorage.setItem('_filtroEventos', JSON.stringify(filtro))
      this.filtro = filtro
    },
    verifPermissao() {
      let user = localStorage.getItem('user')
      user = JSON.parse(jwt.decode(user, 'admin'))
      if(user.id === 1) {
        this.columns.push({
          template: (e) => {
            return {
              template: TemplateNotificacao,
              templateArgs: {
                data: {
                  dados: e,
                  ref: this.$refs.grid.$refs.datasource1
                }
              }
            }
          },
          title: 'Notificação',
          width: 50,
        })
      }
      this.titleNew = this.ACTION.c ? 'Novo' : ''
      if (this.ACTION.e) {
        this.columns.push({
          field: 'ativo',
          title: 'Ações',
          width: 105,
          template: this.commandTemplate
        })
      }
    }
  },
  created() {
    this.verifPermissao()
    let filtro = localStorage.getItem('_filtroEventos')
      ? JSON.parse(localStorage.getItem('_filtroEventos'))
      : null
    if (filtro) {
      this.filtro = filtro
    }
  }
}
</script>
