<template>
    <div>
        <img class="img" :src="templateArgs.data.imagem || imgDefault" @error="placeholder" />
    </div>
</template>

<script>
export default {
    name: 'templateImagem',
    data: () => ({
        templateArgs: {}
    }),
    computed: {
        imgDefault() {
            return require('../../../assets/images/sem-imagem.png')
        }
    },
    methods: {
        placeholder(e) {
            e.target.src = require('../../../assets/images/sem-imagem.png')
        },
    },
}
</script>

<style lang="scss" scoped>
.img {
    width: 70px;
    object-fit: cover;
}
</style>